<template>
  <el-dialog
    :visible.sync="$visible"
    title="完结交付物"
    width="520px"
    @close="onCancel">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="120px"
      label-position="left">
      <el-form-item
        label="文件上传"
        prop="attachments">
        <upload-files
          list-type="custom"
          :limit="1"
          accept=""
          :showTip="false"
          :fileList.sync="formData.attachments">
          <el-button
            size="small"
            type="primary"
            plain>
            点击上传
          </el-button>
        </upload-files>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        size="small"
        @click="onCancel">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="btnLoading"
        @click="onConfirm">
        确认完结
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: String
  },
  data () {
    return {
      formData: {
        attachments: []
      },
      rules: {
        attachments: [
          { required: true, message: '请上传完结交付物', trigger: 'change' }
        ]
      },
      btnLoading: false
    }
  },
  computed: {
    $visible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onCancel () {
      this.$visible = false
      this.$refs.form.resetFields()
    },
    onConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btnLoading = true
          api.finishRequireOrder(this.orderId, {
            ...this.formData
          }).then(res => {
            if (res.data.code === 0) {
              this.$visible = false
              this.$message.success('订单已完结')
              this.$emit('success')
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('请求出错')
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
